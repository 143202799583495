export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/falafel_house_grill_logo.png',
    banner_img_root : '/img/world-cafe-banner-2.png',
    resid : '486511c5b979e8546971b3c210ad6b7b',
    key_value : 'falafelhousecatering',
    secret_value : 'falafelhousecatering',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3071.634800429422!2d-105.0821380852104!3d39.65793277946052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b81cce321737d%3A0xcf53b9e05bf0a971!2sFalafel%20House%20%26%20Grill!5e0!3m2!1sen!2sin!4v1580209731788!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Falafel House & Grill"

};
